<template>
    <div>
    
		<div class="sign-up-header" style="background-image: url('https://demos.creative-tim.com/muse-vue-ant-design-dashboard/images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-3">REGISTRARSE</h1>
				<p class="fs-6">No esperes más, Registrate y obten el sistema totalmente gratis durante 30 dias.</p>
			</div>
		</div>
		
		<a-card :bordered="false" class="card-signup header-solid h-100" :bodyStyle="{paddingTop: 0}">
        
            <template #title>
				<h5 class="font-semibold text-center">Rapido y Sencillo...</h5>
			</template>

			<a-form id="components-form-demo-normal-login" :form="form" layout="vertical" class="login-form" @submit="handleRegister" >
                <a-form-item class="mb-3" label="Número RUC: " name="ruc" :rules="[{ required: true, message: 'Ingresa el RUC' }]" >
                    <a-input-search v-model:value="form.ruc" enter-button="SUNAT" @search="onSearchRUC" placeholder="Ingresa el numero RUC">
                        <template #prefix > <font-awesome-icon :icon="['fas', 'hashtag']" class="site-form-item-icon" /> </template>
                    </a-input-search>
                </a-form-item>

                <a-form-item class="mb-3" label="Razón Social: " name="razon_social" :rules="[{ required: true, message: 'Ingresa la Razón Social.' }]">
                    <a-input v-model:value="form.razon_social" placeholder="Ingresa la Razón Social.">
                        <template #prefix > <font-awesome-icon :icon="['fas', 'store']" class="site-form-item-icon" /> </template>
                    </a-input>
                </a-form-item>

                <a-form-item class="mb-3" label="Dirección: " name="direccion" :rules="[{ required: true, message: 'Ingresa la Dirección.' }]">
                    <a-input v-model:value="form.direccion" placeholder="Ingresa la Dirección.">
                        <template #prefix > <font-awesome-icon :icon="['fas', 'location-dot']" class="site-form-item-icon" /> </template>
                    </a-input>
                </a-form-item>
                
                <a-row :gutter="[16]">
                    <a-col :xs="24" :sm="14" :lg="14" :xl="24" class="gutter-row">
                        <a-form-item class="mb-3" label="Correo Electrónico: " name="correo" :rules="[{ required: true, message: 'Ingresa el Correo Electrónico.' }]">
                            <a-input v-model:value="form.correo" placeholder="Ingresa el Correo Electrónico.">
                                <template #prefix > <font-awesome-icon :icon="['fas', 'at']" class="site-form-item-icon" /> </template>
                            </a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :xs="24" :sm="10" :lg="10" :xl="24" class="gutter-row">
                        <a-form-item class="mb-3" label="Telefono / Celular: " name="telefono" :rules="[{ required: true, message: 'Ingresa el Telefono / Celular.' }]">
                            <a-input v-model:value="form.telefono" placeholder="Ingresa el Telefono / Celular.">
                                <template #prefix > <font-awesome-icon :icon="['fas', 'phone']" class="site-form-item-icon" /> </template>
                            </a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-form-item >
                        <a-checkbox v-model:checked="form.remember">Acepto los <a href="#" class="font-bold">Términos y condiciones</a></a-checkbox>
				</a-form-item>

                <a-form-item class="text-center">
                    <a-button :loading="loading" type="primary" html-type="submit" class="w-100"> REGISTRARSE </a-button>
                </a-form-item>                
			</a-form>
			<p class="font-semibold text-muted text-center">¿Ya tienes una cuenta? <router-link to="/login" class="font-bold text-dark">Iniciar Sesión</router-link></p>
		</a-card>

	</div>
</template>

<script>
    import { defineComponent, reactive, computed } from 'vue';
    import { BorderlessTableOutlined, ShopOutlined } from '@ant-design/icons-vue';
    import { useStore } from "vuex";
    
    export default defineComponent({
        layout: {
            name: 'AuthLayout',
            props: {
                layoutClass: 'layout-sign-up'
            }
        },
        components: {
           BorderlessTableOutlined,
           ShopOutlined,
        },
        setup() {
            const store = useStore();

            const form = reactive({
                ruc: '',
                razon_social: '',
                direccion: '',
                correo_electronico: '',
                telefono: '',
            });

            const loading = computed(() => store.state.auth.loading);
            
            const handleRegister = () => {
                store.dispatch("auth/login", form).then(
                    response => {
                        alert("success")
                    },
                    error => {
                        alert("error")
                    }
                )
            }

            const onSearchRUC = () => {

            }

            return { form, handleRegister, onSearchRUC, loading };
        }
        
    });
</script>